<template>
    <div class="">
        <page-title icon="fas fa-phone" heading="Phone" subheading="All phone calls"></page-title>
        <md-card>
            <div class="card-header" style="overflow: hidden">
                <span class="card-title m-0">{{msg("Calls")}}</span>
            </div>
            <!--Card Content-->
            <md-card-content>
                <!--search bar with accordion-->
                <div class="search-bar">
                    <div class="search-bar-input">
                        <b-input-group>
                            <input type="text" :placeholder="msg('Search')" class="form-control" v-model="filter.search">
                            <b-input-group-append v-b-toggle.collapse1 class="search-toggle">
                                <i class="fa fa-chevron-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                    <div class="search-bar-input">
                        <b-collapse id="collapse1">
                            <form @submit.prevent="filterUpdate" class="row">
                                <div class="col-12 row ml-1 mr-1">


                                    <!--Name-->
                                    <div class="col-3">
                                        <md-field>
                                            <label>{{msg("Source")}}</label>
                                            <md-input v-model="filter.source"/>
                                        </md-field>
                                    </div>
                                    <!--Name-->
                                    <div class="col-3">
                                        <md-field>
                                            <label>{{msg("Destination")}}</label>
                                            <md-input v-model="filter.destination"/>
                                        </md-field>
                                    </div>

                                    <!--Creation Time from-->
                                    <div class="col-3">
                                        <md-field>
                                            <label>{{msg("From")}}</label>
                                            <VueCtkDateTimePicker :button-now-translation="msg('Now')" v-model="filter.from" :locale="currentFormattedLang"  :only-date="true" format="YYYY-MM-DD" formatted="ll" no-header auto-close>
                                                <md-input v-model="filter.from"/>
                                            </VueCtkDateTimePicker>
                                        </md-field>
                                    </div>

                                    <!--Creation Time until-->
                                    <div class="col-3">
                                        <md-field>
                                            <label>{{msg("Until")}}</label>
                                            <VueCtkDateTimePicker :button-now-translation="msg('Now')" v-model="filter.until" :locale="currentFormattedLang" :only-date="true" format="YYYY-MM-DD" formatted="ll" no-header auto-close>
                                                <md-input v-model="filter.until"/>
                                            </VueCtkDateTimePicker>
                                        </md-field>
                                    </div>
                                </div>
                            </form>
                        </b-collapse>
                    </div>
                </div>
                <!--Table-->
                <table  class="table b-table table-hover" v-bind:class="{'table-striped':isLoaded}">
                    <thead>
                    <tr>
                        <!--id: 0, name: "", price: 0.0, duration: 0, durid: 0, description: "", roles: []-->
                        <th @click="sort('id')" aria-sort="none"><a>Id</a></th>
                        <th @click="sort('source')" aria-sort="none">{{msg('Source')}}</th>
                        <th @click="sort('destination')" aria-sort="none">{{msg('Destination')}}</th>
                        <th @click="sort('creationtime')" aria-sort="none">{{msg('Creation time')}}</th>
                        <th @click="sort('fileId')" aria-sort="none">{{msg('FileId')}}</th>
                        <th @click="sort('duration')" aria-sort="none">{{msg('Duration')}}</th>
                        <th @click="sort('duration')" aria-sort="none">{{msg('Status')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <!--Table-->
                    <template v-if="isLoaded">
                        <tr v-for="call in getCalls" v-bind:key="call.id" @click="onCallSelect(call.id);dialogs.call=true">
                            <td>{{call.id}}</td>
                            <td>{{call.source}}</td>
                            <td>{{call.destination}}</td>
                            <td>{{call.creationtime}}</td>
                            <td>{{call.fileId}}</td>
                            <td>{{call.duration}}</td>
                            <td>{{call.statusName}}</td>
                        </tr>
                    </template>
                    <!--Error-->
                    <template v-else-if="isLoaded !== true && error !== ''">
                        <tr class="table-loading">
                            <td colspan="9">
                                <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
                                    <strong>{{error}}</strong>
                                </b-alert>
                            </td>
                        </tr>
                    </template>
                    <!--Loading-->
                    <template v-else>
                        <tr class="table-loading">
                            <td colspan="9">
                                <p>
                                    <b-spinner label="Spinning"/>
                                    {{msg("loading")}}
                                </p>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>



            </md-card-content>
        </md-card>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
     import PageTitle from "@/@core/components/page-title/PageTitle";

    export default {
        name: "Call",
        components:{PageTitle},
        data() {
            return {

                selected: 0,
                loaded: false,
                error: '',
                filter: {},
                tableSort: {},
                dialogs: {call: false},
                //Listeners
                listeners: {refresh:()=>{}}

            }
        },
        watch: {
            filter: {
                deep: true,
                handler() {
                    this.refresh()
                }
            }
        },
        computed: {
            ...mapGetters('translator', ['msg','currentFormattedLang']),
            ...mapGetters('phone', ['getCalls','isLoaded']),
        },
        created() {
            let $this = this;
            this.getAllCalls({}).then(() => {
                $this.loaded = true;
            }, (error) => $this.error = error);
            this.$root.$on('crm::branch-updated',(this.listeners.refresh = () => {
                this.refresh({});
            }));
        },
        beforeDestroy() {
            this.$root.$off('crm::branch-updated',this.listeners.refresh)
        },
        methods: {
            ...mapActions('phone', ['getPhoneRecords','getAllCalls']),
            onCallSelect(id) {
                this.selected = id;
            },
            refresh(){
                let $this = this;
                this.loaded = false;
                this.getAllCalls({filter:this.filter}).then(() => {
                    $this.loaded = true;
                }, (error) => $this.error = error);
            },
            filterUpdate() {
                this.selectedRows = [];
                this.refresh({filter: this.filter})

            },
            sort(col) {
                if (this.tableSort.sortcolumn !== col) {
                    this.tableSort.sortby = 'asc';
                    this.tableSort.sortcolumn = col;
                    return;
                }
                this.tableSort.sortby = this.tableSort.sortby === 'asc' ? 'desc' : 'asc';
            },
            sortStatus(col) {
                if (this.tableSort.sortcolumn !== col)
                    return "none";
                return this.tableSort.sortby === 'asc' ? 'ascending' : 'descending'
            },
            submitCall() {
                this.$root.$emit('crm::call::submit')
            },
        }
    }
</script>

<style >

</style>
